import React, { Component } from "react"
import Markdown from "react-markdown"

export default class Body extends Component {
  bodyElement() {
    if (this.props.properties && this.props.properties.body) {
      const body =
        typeof this.props.body == "object"
          ? this.props.body.contents
          : this.props.body
      return <Markdown source={body} {...this.props.properties.body} />
    } else {
      return <h5 data-caption-animate="fadeInUp">{this.props.body}</h5>
    }
  }

  render() {
    return <>{this.bodyElement()}</>
  }
}

Body.defaultProps = {
  properties: {
    body: {},
  },
  body: "",
}
