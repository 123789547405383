import React, { Component } from "react"
import Markdown from "react-markdown"

export default class Testimonial extends Component {
  render() {
    const jsonProps =
      this.props.properties || Testimonial.defaultProps.properties
    return (
      <li className="slide" key={this.props.id}>
        <div className="testimonial-content">
          <div className="testimonial-quote">
            <Markdown source={this.props.body} />
          </div>
          <div className="testimonial-attribution">
            {jsonProps.attribution && (
              <>
                <strong>{jsonProps.attribution.title}</strong>
                <br />
              </>
            )}
            {this.props.title}
          </div>
        </div>
      </li>
    )
  }
}

Testimonial.defaultProps = {
  properties: {
    attribution: {
      // title: "...",
    },
  },
}
