import React, { Component } from "react"
import Markdown from "react-markdown"

export default class Inline extends Component {
  containerClasses() {
    let classes = ["container", "clearfix"]
    if (this.jsonProps.arrow) classes.push("down-caret")
    return classes.join(" ")
  }

  blocks() {
    if ((this.props.blocks || []).length === 0) return null
    return this.props.blocks.map((block, idx) => (
      <div className="heading-block heading-block--home center" key={idx}>
        <h2>{block.title}</h2>
        <Markdown source={block.body} />
      </div>
    ))
  }

  render() {
    this.jsonProps = this.props.properties || Inline.defaultProps

    return (
      <div className={this.containerClasses()} arrow={this.jsonProps.arrow}>
        {this.blocks()}
      </div>
    )
  }
}

Inline.defaultProps = {
  arrow: undefined,
}
