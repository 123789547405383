import React, { Component } from "react"

export default class Callout extends Component {
  handleClick() {
    console.log("CLICK!")
  }

  blocks() {
    return this.props.blocks.map((block, idx) => (
      <span key={idx}>
        <span>{block.title}</span>
        {block.ctaLabel && block.ctaLink && (
          <a
            href={block.ctaLink}
            className="button button-border button-light button-rounded button-large keyline-button-hover"
            onClick={this.handleClick}
          >
            {block.ctaLabel}
          </a>
        )}
      </span>
    ))
  }

  render() {
    return (
      <div
        className=" take-a-look row clearfix center bottommargin-lg"
        style={{ backgroundColor: "rgb(6, 48, 88)" }}
      >
        {this.blocks()}
      </div>
    )
  }
}

Callout.defaultProps = {
  blocks: [],
}
