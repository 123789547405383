import React, { Component } from "react"
import chunk from "lodash/chunk"
import Markdown from "react-markdown"

import BackgroundImage from "../background-image"
import Image from "../image"

export default class InfoGrid extends Component {
  backgroundClasses() {
    let classes = []
    if (this.jsonProps.infoGridType === "brand-goals")
      classes.push("brand-goals brand-goals-bg")
    if (this.jsonProps.infoGridType === "measure-success")
      classes.push("clearfix measure-success parallax")

    return classes.join(" ")
  }

  columnClasses() {
    let classes = []
    if (this.jsonProps.infoGridType === "brand-goals")
      classes.push("col-md-4 col-md-4 bottommargin")
    if (this.jsonProps.infoGridType === "measure-success")
      classes.push("fb-col-4 dark col-padding col-solutions")

    return classes.join(" ")
  }

  contentClasses() {
    let classes = []
    if (this.jsonProps.infoGridType === "brand-goals") classes.push("bg-goals")

    return classes.join(" ")
  }

  containerClasses() {
    let classes = ["container", "clearfix"]
    if (this.jsonProps.infoGridType === "measure-success")
      classes.push("center")

    return classes.join(" ")
  }

  titleBlocks() {
    return (this.props.blocks || [])
      .filter(block => ["Intro", "Heading"].includes(block.type))
      .map((block, idx) => {
        if (block.type === "Heading") return <h3 key={idx}>{block.title}</h3>
        return (
          <div className="center" key={idx}>
            <h1 className="white padding-top-30">{block.title}</h1>
            <p className="white">{block.body}</p>
          </div>
        )
      })
  }

  chunk(chunk, idx) {
    if (this.jsonProps.infoGridType === "brand-goals") {
      return (
        <div className="row mobile-break" key={idx}>
          {chunk}
        </div>
      )
    } else {
      return (
        <div className="fb-row clearfix" key={idx}>
          {chunk}
        </div>
      )
    }
  }

  featureBox(block) {
    if (this.jsonProps.infoGridType === "brand-goals") {
      return (
        <div className="feature-box topmargin" data-animate="fadeIn">
          {block.image && (
            <span className="large i-circled">
              <Image src={block.image.url} alt="" />
            </span>
          )}
          <div className={this.contentClasses()}>
            <h4 dangerouslySetInnerHTML={{ __html: block.title }} />
            <Markdown source={block.body} />
          </div>
        </div>
      )
    } else {
      return (
        <div className={this.contentClasses()}>
          <h4>{block.title}</h4>
          <Markdown source={block.body} />
        </div>
      )
    }
  }

  blocks() {
    const blocks = (this.props.blocks || []).filter(
      block => !["Intro", "Heading"].includes(block.type)
    )
    if (blocks.length === 0) return null

    return chunk(
      blocks.map((block, idx) => (
        <div className={this.columnClasses()} key={idx}>
          {this.featureBox(block)}
        </div>
      )),
      3
    ).map((chunk, idx) => (
      <div key={`block-${idx}`}>{this.chunk(chunk, idx)}</div>
    ))
  }

  render() {
    this.jsonProps = this.props.properties || InfoGrid.defaultProps

    return (
      <BackgroundImage
        className={this.backgroundClasses()}
        Tag="div"
        src={this.props.image.url}
      >
        <div className={this.containerClasses()}>
          <div className="row topmargin-lg bottommargin-sm">
            {this.titleBlocks()}
            {this.blocks()}
          </div>
        </div>
      </BackgroundImage>
    )
  }
}

InfoGrid.defaultProps = {
  infoGridType: undefined,
}
