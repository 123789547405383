import React, { Component } from "react"

export default class Stats extends Component {
  introBlocks() {
    return this.props.blocks
      .filter(b => b.type === "Intro")
      .map((block, idx) => (
        <div className="center advantage-header" key={idx}>
          <h1 className="white">{block.title}</h1>
          <p className="white">{block.body}</p>
        </div>
      ))
  }

  blocks() {
    return this.props.blocks
      .filter(block => block.type !== "Intro")
      .map((block, idx) => {
        return (
          <div className="fb-col-4 dark center counter-padding" key={idx}>
            <div>
              <div className="counter counter-lined">
                <span
                  data-from={block.properties.from}
                  data-to={block.properties.to}
                  data-decimals={block.properties.decimals || 0}
                  data-refresh-interval={
                    block.properties["refresh-interval"] || 50
                  }
                  data-speed={block.properties.speed || 1000}
                />
                {block.title.replace(block.properties.to, "")}
              </div>
              <h5>{block.body}</h5>
            </div>
          </div>
        )
      })
  }

  render() {
    return (
      <div className="clearfix advantage advantage-bg">
        {this.introBlocks()}
        <div className="fb-row clearfix">{this.blocks()}</div>
      </div>
    )
  }
}

Stats.defaultProps = {
  blocks: [],
}
