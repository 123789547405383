import React, { Component } from "react"

import BackgroundImage from "../background-image"
import Body from "../blocks/body"

export default class StepGrid extends Component {
  steps() {
    return this.props.blocks.map((block, i) => {
      return (
        <div className="col_one_fourth" key={i}>
          <div
            className="feature-box fbox-center "
            data-animate="fadeIn"
            data-delay={i * 200}
          >
            <div className="fbox-icon">
              <i>{i + 1}</i>
            </div>
            <div className="fbox-copy">
              <h3>{block.title}</h3>
              <Body {...block} properties={{ body: {} }} />
            </div>
          </div>
        </div>
      )
    })
  }

  lines() {
    return this.props.blocks.slice(1).map((block, i) => {
      return (
        <div
          className={"line l-" + (i + 1)}
          data-animate="fadeIn"
          data-delay={200 * i + 100}
          key={i}
        />
      )
    })
  }

  render() {
    return (
      <BackgroundImage
        className="clearfix partnership parallax leverage"
        Tag="div"
        src={this.props.image.url}
      >
        <div className="center">
          <h1 className="white font-size-24">{this.props.title}</h1>
          <Body {...this.props} body={this.props.properties.body} />
          <h3>How we work together:</h3>
        </div>
        <div className="list-container">
          {this.lines()}
          {this.steps()}
        </div>
      </BackgroundImage>
    )
  }
}
