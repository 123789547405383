import React, { Component } from "react"
import chunk from "lodash/chunk"
import Markdown from "react-markdown"

import Image from "../image"

export default class Wheel extends Component {
  introBlockClasses() {
    let classes = ["heading-block", "center"]
    if (this.jsonProps.libby) classes.push("libby-header")
    return classes.join(" ")
  }

  introBlocks() {
    return this.props.blocks
      .filter(b => b.type === "Intro")
      .map((block, idx) => (
        <div className={this.introBlockClasses()} key={idx}>
          <h2>{block.title}</h2>
          <div className="divcenter">
            <Markdown source={block.body} />
          </div>
        </div>
      ))
  }

  featureBoxClasses(isLeft, idx) {
    let classes = [
      "feature-box",
      `fbox-${isLeft ? "right" : "left"}`,
      `fb-${idx + 1}`,
    ]

    if (!this.jsonProps.libby) classes.push("topmargin")
    return classes.join(" ")
  }

  columnClasses() {
    let classes = []
    if (this.jsonProps.libby)
      classes.push(
        "col-md-4",
        "col-sm-6",
        "left-col",
        "right-container",
        "libby-info"
      )
    if (!this.jsonProps.libby)
      classes.push("col-md-6", "col-sm-6", "bottommargin")
    return classes.join(" ")
  }

  blocks() {
    const blocks = (this.props.blocks || []).filter(
      block => block.type !== "Intro"
    )
    if (blocks.length === 0) return null

    const headings = blocks.filter(b => b.type === "Heading")
    const icons = blocks.filter(b => b.type === "Default")

    return chunk(
      blocks.map((block, idx) => {
        if (block.type === "Heading") {
          return (
            <h2
              className={`ico-header ic-${headings.indexOf(block) + 1}`}
              key={idx}
            >
              {block.title}
            </h2>
          )
        }
        const isLeft = idx / blocks.length < 0.5
        return (
          <div
            className={this.featureBoxClasses(isLeft, icons.indexOf(block))}
            data-animate="fadeIn"
            data-delay="300"
            key={idx}
          >
            <div className="fbox-icon" key="fbox">
              <Image className="i-circled" src={block.image.url} />
            </div>
            <div className="info" key="info">
              <h3>{block.title}</h3>
              <p>{block.body}</p>
            </div>
          </div>
        )
      }),
      blocks.length / 2
    ).map((chunk, idx) => (
      <div key={idx}>
        <div className={this.columnClasses()} key={idx}>
          {chunk}
        </div>
        {idx === 0 && this.jsonProps.libby ? (
          <div className="col-md-4 hidden-sm hidden-xs bottommargin center" />
        ) : null}
      </div>
    ))
  }

  blockContentClasses() {
    let classes = []
    if (this.jsonProps.libby) classes.push("libby-container")
    if (!this.jsonProps.libby) classes.push("boc-bg")
    return classes.join(" ")
  }

  blockContent() {
    return (
      <div className="row bottommargin-sm">
        {this.introBlocks()}
        <div
          className={this.blockContentClasses()}
          style={{ backgroundImage: `url(${this.props.image.url})` }}
        >
          {this.blocks()}
        </div>
      </div>
    )
  }

  render() {
    this.jsonProps = this.props.properties || Wheel.defaultProps

    if (this.jsonProps.libby) {
      return (
        <div className="map">
          <div className="container clearfix customer-header">
            {this.blockContent()}
          </div>
        </div>
      )
    } else {
      return (
        <div className="map">
          <div className="container clearfix">
            <div className="boc">{this.blockContent()}</div>
          </div>
        </div>
      )
    }
  }
}

Wheel.defaultProps = {
  blocks: [],
  libby: undefined,
}
