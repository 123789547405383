import React, { Component } from "react"

import BackgroundImage from "gatsby-background-image"

export default class KPMBackgroundImage extends Component {
  styles() {
    let styles = { ...this.props.style }
    styles["backgroundImage"] = `url("${this.props.src}")`
    styles["backgroundPosition"] = styles["backgroundPosition"] || "center"
    styles["backgroundSize"] = styles["backgroundSize"] || "cover"
    styles["backgroundRepeat"] = styles["backgroundRepeat"] || "no-repeat"
    return styles
  }

  render() {
    if (this.fixed || this.fluid)
      return (
        <BackgroundImage {...this.props}>{this.props.children}</BackgroundImage>
      )
    const TagName = this.props.Tag
    return (
      <TagName
        className={this.props.className}
        style={this.styles()}
        id={this.props.id}
        {...this.props.heightParams}
      >
        {this.props.children}
      </TagName>
    )
  }
}

KPMBackgroundImage.defaultProps = {
  className: null,
  heightParams: {},
  fluid: null,
  fixed: null,
  id: null,
  src: null,
  style: {},
  Tag: "div",
}
