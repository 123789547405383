import React, { Component } from "react"
import Testimonial from "../blocks/testimonial"

export default class Testimonials extends Component {
  componentDidMount() {
    const $ = window.jQuery
    $(".testimonials-slider").flexslider({
      animation: "slide",
      randomize: true,
      slideshowSpeed: 12000,
    })
  }
  slides() {
    return this.props.blocks.map((block, idx) => (
      <Testimonial {...block} key={idx} />
    ))
  }
  render() {
    return (
      <div className="testimonials-container">
        <h1>{this.props.title}</h1>
        <div className="testimonials-slider">
          <ul className="slides">{this.slides()}</ul>
        </div>
      </div>
    )
  }
}
