import React, { Component } from "react"

export default class Tabs extends Component {
  handleClick() {
    console.log("CLICK")
  }

  introBlocks() {
    return this.props.blocks
      .filter(block => ["Intro"].includes(block.type))
      .map((block, idx) => (
        <div className="container clearfix center" key={idx}>
          <h1 className="solutions-header">{block.title}</h1>
          <h4 className="padded">{block.body}</h4>
        </div>
      ))
  }

  tabs() {
    this.defaultBlocks = this.props.blocks.filter(b => b.type === "Default")
    return this.defaultBlocks.map((block, idx) => (
      <li key={idx}>
        <a href={`#tabs-${idx + 1}`} onClick={this.handleClick}>
          {block.title}
        </a>
      </li>
    ))
  }

  blocks() {
    return this.props.blocks
      .filter(b => b.type === "Default")
      .map((block, idx) => (
        <div className="tab-content clearfix" id={`tabs-${idx + 1}`} key={idx}>
          <div dangerouslySetInnerHTML={{ __html: block.body }} />
        </div>
      ))
  }

  render() {
    return (
      <div className="container clearfix objective-solutions">
        {this.introBlocks()}
        <div className="tabs tabs-alt clearfix" id="tab-7">
          <ul className="tab-nav clearfix">{this.tabs()}</ul>
          <div className="tab-container">{this.blocks()}</div>
        </div>
      </div>
    )
  }
}

Tabs.defaultProps = {
  blocks: [],
}
