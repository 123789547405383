import React from "react"

import Callout from "./callout"
import Downloads from "./downloads"
import ImageLeft from "./image-left"
import ImageRight from "./image-right"
import InfoGrid from "./info-grid"
import Inline from "./inline"
import Parallax from "./parallax"
import Stats from "./stats"
import StepGrid from "./step-grid"
import Tabs from "./tabs"
import Testimonials from "./testimonials"
import Tiles from "./tiles"
import Wheel from "./wheel"

export default class Containers extends React.Component {
  components = {
    Callout: Callout,
    Downloads: Downloads,
    ImageLeft: ImageLeft,
    ImageRight: ImageRight,
    InfoGrid: InfoGrid,
    Inline: Inline,
    Parallax: Parallax,
    Stats: Stats,
    StepGrid: StepGrid,
    Tabs: Tabs,
    Testimonials: Testimonials,
    Tiles: Tiles,
    Wheel: Wheel,
  }

  render() {
    let data = { ...this.props.data }
    if (typeof data != Array) data = [data]

    return this.props.data.map((node, idx) => {
      const TagName = this.components[node.type]
      return <TagName {...node} key={idx} />
    })
  }
}

Containers.defaultProps = {
  data: [],
}
