import React, { Component } from "react"
import Markdown from "react-markdown"

import BackgroundImage from "../background-image"

export default class Tiles extends Component {
  classNames() {
    const n = parseInt(12 / this.props.blocks.length) || 4
    return ["fb-col-" + n, "dark", "center", "view"].join(" ")
  }

  blocks() {
    if ((this.props.blocks || []).length === 0) return null
    return this.props.blocks.map((block, idx) => (
      <BackgroundImage
        className={this.classNames()}
        style={{
          height: "370px",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
        Tag="div"
        src={block.image.url}
        key={idx}
      >
        <h4 className="white">{block.title}</h4>
        <div className="local-overlay">
          <h4>{block.title}</h4>
          <Markdown source={block.body} />
        </div>
      </BackgroundImage>
    ))
  }

  render() {
    return <div className="fb-row clearfix solutions-row">{this.blocks()}</div>
  }
}
