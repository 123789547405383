/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from "react"

export default class scrollArrow extends Component {
  render() {
    return (
      <div className="relative-container">
        <a
          href="#"
          data-scrollto="#content"
          data-offset="200"
          className="dark down-arrow infinite animated slower fadeInDown topmargin-sm"
        >
          <i className="icon-angle-down" />
        </a>
      </div>
    )
  }
}
