import React, { Component } from "react"
import Body from "./body"

export default class Download extends Component {
  onClick() {}

  links() {
    return (this.props.properties.downloads || []).map((dl, i) => {
      return (
        <a
          href={dl.href || "#"}
          download={this.props.title}
          className="button button-border button-light button-rounded blue-button-hover"
          onClick={this.onClick()}
          key={i}
        >
          {dl.title}
        </a>
      )
    })
  }

  render() {
    return (
      <li className="download-container" key={this.props.id}>
        <div className="download-header-container">
          <h3 dangerouslySetInnerHTML={{ __html: this.props.title }} />
        </div>
        {this.props.body && (
          <div className="download-copy-container">
            <Body body={this.props.body} />
          </div>
        )}
        {this.props.properties && (
          <div className="download-button-container">
            {!this.props.body && <div className="space-filler" />}
            {this.links()}
          </div>
        )}
      </li>
    )
  }
}

Download.defaultProps = {
  title: "",
  body: "",
  properties: {
    downloads: [],
  },
}
