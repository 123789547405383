import React, { Component } from "react"

import BackgroundImage from "../background-image"
import Body from "../blocks/body"

export default class Parallax extends Component {
  handleClick() {
    console.log("clicked!")
    // ga('send', 'event', 'Homepage', 'Make a Personal Connection', 'View Solutions');
  }

  buttonStyles() {
    let styles = { ...this.props.style }
    if (this.isFullscreen)
      styles["marginBottom"] = styles["marginBottom"] || "100px"
    return styles
  }

  parallaxClasses() {
    let classes = [
      "slider-parallax",
      "swiper_wrapper",
      "slider-parallax-visible",
      "clearfix parallax",
    ]
    if (this.isFullscreen) classes.push("full-screen")
    return classes.join(" ")
  }

  captionClasses() {
    let classes = ["slider-caption", "slider-caption-center"]
    if (this.isWideCaption) classes.push("wide-caption")
    return classes.join(" ")
  }

  parallaxContent(block) {
    if (this.hasDataHeightAttributes === true || this.isFullscreen) {
      return (
        <div className="slider-parallax-inner">
          <div className="swiper-slide dark">
            <div className="container clearfix">
              <div className={this.captionClasses()}>
                <h1
                  data-caption-animate="fadeInUp"
                  className="white font-size-24"
                >
                  {block.title}
                </h1>
                <Body {...block} />
                {block.ctaLabel && block.ctaLink && (
                  <a
                    href={block.ctaLink}
                    className="button button-border button-light button-rounded button-large noleftmargin topmargin-sm keyline-button-hover"
                    style={this.buttonStyles()}
                    onClick={this.handleClick}
                  >
                    {block.ctaLabel}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="center">
          <h1 className="white font-size-24">{block.title}</h1>
          <span className="white">{block.body}</span>
        </div>
      )
    }
  }

  render() {
    const block = this.props.blocks[0]
    if (!block) return null
    const jsonProps = this.props.properties || {}

    this.isFullscreen = jsonProps.fullscreen || Parallax.defaultProps.fullscreen
    this.isWideCaption =
      jsonProps.wideCaption || Parallax.defaultProps.wideCaption
    this.hasDataHeightAttributes =
      jsonProps.dataHeightAttributes ||
      Parallax.defaultProps.dataHeightAttributes

    let heightParams = {}

    if (this.hasDataHeightAttributes === true && !this.isFullscreen) {
      heightParams = {
        "data-height-lg": 670,
        "data-height-md": 500,
        "data-height-sm": 400,
        "data-height-xs": 350,
      }
    }

    return (
      <BackgroundImage
        id={jsonProps["id"] || "slider"}
        className={jsonProps["className"] || this.parallaxClasses()}
        data-stellar-background-ratio="0.4"
        Tag={jsonProps["Tag"] || "section"}
        src={this.props.image.url}
        heightParams={heightParams}
      >
        <>{this.parallaxContent(block)}</>
      </BackgroundImage>
    )
  }
}

Parallax.defaultProps = {
  blocks: [],
  dataHeightAttributes: true,
  fullscreen: undefined,
  properties: {},
  wideCaption: undefined,
}
