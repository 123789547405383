import React, { Component } from "react"
import Markdown from "react-markdown"

import BackgroundImage from "../background-image"

export default class ImageLeft extends Component {
  cta(block) {
    if (!block.ctaLabel || !block.ctaLink) return null
    return (
      <a
        href={block.ctaLink}
        className="button button-border button-light button-rounded button-large noleftmargin blue-button-hover"
        onClick={this.handleClick}
      >
        {block.ctaLabel}
      </a>
    )
  }

  handleClick() {
    return true
  }

  containerClasses() {
    let classes = ["fb-row", "clearfix", "img-callout", "home-callouts"]
    if (this.jsonProps.hasWhiteBackground) classes.push("has-white-background")
    return classes.join(" ")
  }

  styles() {
    let styles = { ...this.props.style }
    if (!this.jsonProps.hasWhiteBackground) {
      styles["backgroundColor"] = "#0068b3"
    }
    return styles
  }

  render() {
    const block = this.props.blocks[0]
    if (!block) return null

    this.jsonProps = this.props.properties || ImageLeft.defaultProps

    return (
      <div className={this.containerClasses()} style={this.styles()}>
        <BackgroundImage
          className="fb-col-6 col-padding content-image"
          Tag="div"
          src={this.props.image.url}
        />
        <div className="fb-col-6 center">
          <div className="heading-block nobottomborder content-box-fix">
            <h3>{block.title}</h3>
            <Markdown source={block.body} />
            {this.cta(block)}
          </div>
        </div>
      </div>
    )
  }
}

ImageLeft.defaultProps = {
  hasWhiteBackground: false,
}
