import React, { Component } from "react"
import Download from "../blocks/download"

export default class Downloads extends Component {
  componentDidMount() {
    const $ = window.jQuery
    $(".downloads-container").flexslider({
      animation: "slide",
    })
  }

  blocks() {
    return this.props.blocks.map((block, idx) => (
      <Download {...block} key={idx} />
    ))
  }

  render() {
    const jsonProps = this.props.properties || {}
    return (
      <div
        className={jsonProps.className || "template-body downloads-container"}
      >
        <ul className="slides">{this.blocks()}</ul>
      </div>
    )
  }
}

Downloads.defaultProps = {
  properties: {},
}
